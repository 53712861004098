<template>
  <div>
    <el-tree
      :data="list"
      node-key="id"
      :props="{ children: 'child' }"
      default-expand-all
    >
      <div class="custom-tree-node" slot-scope="{ data }">
        <div class="lv_flexalign_center">
          <div>{{ data.name }}</div>
          <div
            class="color_item"
            :style="{ background: data.color }"
            v-if="data.color"
          ></div>
          <div class="lv_flexalign_center" style="margin-left: 15px">
            <el-button
              type="text"
              size="mini"
              @click.stop="() => edit(data, 'add')"
            >
              新增子分类
            </el-button>
            <el-button
              type="text"
              size="mini"
              @click.stop="() => edit(data, 'edit')"
            >
              编辑
            </el-button>
          </div>
        </div>
      </div>
    </el-tree>
    <el-dialog
      :title="!item.id ? '新增' : '编辑'"
      :visible.sync="show"
      width="500px"
    >
      <el-form label-width="80px">
        <el-form-item label="标签名称">
          <el-input v-model="item.name"></el-input>
        </el-form-item>
        <el-form-item label="图标">
          <el-input v-model="item.iconUrl"></el-input>
        </el-form-item>
        <el-form-item label="色值">
          <el-color-picker v-model="item.color"></el-color-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="confirm">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      item: {},
      show: false,
    };
  },
  methods: {
    getList() {
      this.$ajax.post("/admin/classifyManage/queryAll").then((res) => {
        this.list = res.data;
      });
    },
    edit(item, type) {
      if (type === "edit") {
        this.item = {
          color: item.color,
          iconUrl: item.iconUrl,
          id: item.id,
          name: item.name,
          parentId: item.parentId,
        };
      } else {
        this.item = {
          color: "",
          iconUrl: "",
          id: "",
          name: "",
          parentId: item.id,
        };
      }
      this.show = true;
    },
    confirm() {
      this.$ajax.post("/admin/classifyManage/save", this.item).then((res) => {
        this.$message.success("保存成功");
        this.show = false;
        this.getList();
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.color_item {
  width: 15px;
  height: 15px;
  border: 1px solid #eee;
  margin-left: 15px;
}
</style>